<!--
  功能：功能描述
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2022年01月13日 13:29:57
-->
<template>
<div id="panel">
        <slot name="outer"></slot>
</div>
</template>

<script>
export default {
    // 组件名称
    name: 'Panel',
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {}
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {},
    //在实例初始化之后，组件属性计算之前，如data属性等
    beforeCreate() {},
    //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    created() {},
    //在挂载开始之前被调用：相关的 render 函数首次被调用。
    beforeMount() {},
    //挂载后
    mounted() {},
    //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
    beforeUpdate() {},
    //数据更新时后调用
    updated() {},
    // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
    activated() {},
    // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
    deactivated() {},
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
    beforeDestroy() {},
    //Vue 实例销毁后调用。
    destroyed() {}
}
</script> 

<style lang="scss" scoped>
#panel {
    width: 100%;
    height: auto;
    background: linear-gradient(to left, #07b9e6, #07b9e6) left top no-repeat,
        linear-gradient(to bottom, #07b9e6, #07b9e6) left top no-repeat,
        linear-gradient(to left, #07b9e6, #07b9e6) right top no-repeat,
        linear-gradient(to bottom, #07b9e6, #07b9e6) right top no-repeat,
        linear-gradient(to left, #07b9e6, #07b9e6) left bottom no-repeat,
        linear-gradient(to bottom, #07b9e6, #07b9e6) left bottom no-repeat,
        linear-gradient(to left, #07b9e6, #07b9e6) right bottom no-repeat,
        linear-gradient(to left, #07b9e6, #07b9e6) right bottom no-repeat;
    background-size: 2px 25px, 25px 2px, 2px 25px, 25px 2px;
    background-color: rgb(9, 18, 45,0.75);
    // background-image: linear-gradient( 135deg, #97ABFF 10%, #123597 100%);
    box-shadow: inset 0 0 3px #253954;
    border: 1px solid #253954;
}
</style>
