<!--
  功能：功能描述
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2022年01月12日 14:30:48
-->
<template>
  <div id="visualization">
    <!-- 导航栏 -->
    <div class="nav-container">
      <Navigation class="uk-animation-slide-top"></Navigation>
    </div>
    <div class="map-container" id="TenChong"></div>
    <div class="card-container">
      <CheckStatus></CheckStatus>
      <CheckRank></CheckRank>
      <TaskTendency></TaskTendency>
    </div>
    <div class="roll-container">
      <centerTopMenu></centerTopMenu>
      <!-- <BottomMenu @change="changeMenu"></BottomMenu> -->
    </div>
    <div class="center-bottom-container">
      <CenterContent></CenterContent>
    </div>
    <div class="subentry-container">
      <AccessStatus></AccessStatus>
      <FoodSearch></FoodSearch>
      <SampleDistributed></SampleDistributed>
    </div>

    <!-- <Modal
      v-model="modelState"
      title="项目详情"
      width="700"
      style="background: red"
    >
      <Form :model="formItem" :label-width="80">
        <FormItem label="项目名称">
          <Input :border="false" :value="formItem.name"></Input>
        </FormItem>
        <FormItem label="计划投入">
          <Input :border="false" :value="formItem.invest"></Input>
        </FormItem>
        <FormItem label="实际投入">
          <Input :border="false" :value="formItem.practical"></Input>
        </FormItem>
        <FormItem label="占地面积">
          <Input :border="false" :value="formItem.area"></Input>
        </FormItem>
        <FormItem label="项目概况">
          <Input :border="false" :value="formItem.content"></Input>
        </FormItem>
        <FormItem label="备注信息">
          <Input :border="false" :value="formItem.remark"></Input>
        </FormItem>
      </Form>
    </Modal> -->
  </div>
</template>

<script>
import {
  Navigation,
  Panel,
  BarChart,
  InstrumentChart,
  PieChart,
  WordChart,
  BottomMenu,
  CheckStatus,
  CheckRank,
  AccessStatus,
  SampleDistributed,
  FoodSearch,
  TaskTendency,
  CenterContent,
  centerTopMenu,
} from "./components/index.js";
//import TenchongData from "./json/data.json";
//import hangzhouData from "./json/hangzhou.json";
import hezeData from "./json/heze.json";
export default {
  // 组件名称
  name: "Visualization",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {
    Navigation,
    Panel,
    BarChart,
    InstrumentChart,
    PieChart,
    WordChart,
    BottomMenu,
    CheckStatus,
    CheckRank,
    AccessStatus,
    SampleDistributed,
    FoodSearch,
    TaskTendency,
    CenterContent,
    centerTopMenu,
  },
  watch: {
    showMarker(n, o) {
      if (!window.marker || n == o) return;
      console.log(window.marker);
      if (n == 1) {
        for (let i = 0; i < window.marker.length; i++) {
          window.marker[i].show();
        }
      } else {
        for (let i = 0; i < window.marker.length; i++) {
          window.marker[i].hide();
        }
      }
    },
  },
  // 组件状态值
  data() {
    return {
      showMarker: false,
      modelState: false,
      interval: null,
      bg: "../../assets/images/home.png",
      url: "http://dianimage.hiyouj.com/ff909640f8c249fcad5aa4ee76c30e21.png",
      mapObj: null, //地图对象
      mapLoca: null, //地图可视化对象
      configData: {
        header: ["任务名称", "所属项目", "所属公司"],
        headerBGC: "#0e1e4e",
        headerHeight: 34,
        rowNum: 5,
        oddRowBGC: "#2a3042",
        evenRowBGC: "rgb(9, 18, 45)",
        data: [
          ["固东镇厕所改造", "固东厕改", "云南建投第九公司"],
          ["北海乡生猪屠宰场", "生猪屠宰场", "云南乡村振兴公司"],
          ["滇滩镇农贸市场", "农贸市场", "腾冲市乡镇发改委"],
          ["和顺镇河道治理", "河道治理", "云南建投第二公司"],
          ["固东镇河道治理", "河道治理", "云南建投第九公司"],
          ["荷花镇马拉松改建", "马拉松", "云南建投第三公司"],
          ["猴桥镇农贸市场", "农贸市场", "云南建投第四公司"],
          ["界头镇厕所改造", "厕所厕改", "云南建投第九公司"],
        ],
      },
      tableData: {
        header: ["事项名称", "事项内容", "上报内容"],
        headerBGC: "#0e1e4e",
        headerHeight: 34,
        rowNum: 5,
        oddRowBGC: "#2a3042",
        evenRowBGC: "rgb(9, 18, 45)",
        data: [
          ["北海乡厕所地基", "已完成", "2022-01-17"],
          ["固东镇生猪屠宰场征地", "正在与居民协商", "2022-01-16"],
          ["滇滩镇农贸市场改建", "项目完成60%", "2022-01-15"],
          ["和顺镇河道围栏建设", "项目开始动工", "2022-01-14"],
          ["固东镇厕所改造建设地址考察", "进行中", "2022-01-13"],
          ["荷花镇马拉松改建", "项目计划06月启动", "2022-01-12"],
          ["猴桥镇农贸市场竣工", "项目已完成", "2022-01-11"],
          ["界头镇厕所封顶", "计划余04月竣工交付", "2022-01-10"],
        ],
      },
      scatterYellow: null, //立体图层
      scatterBlue: null, //蓝色点
      triangleZMarker: null, //浮动三角
      successUrl:
        "http://dianimage.hiyouj.com/db48cedb8c7742268e271c64babc5f2a.png",
      formItem: {},
      geoList: [],
    };
  },

  // 组件方法
  methods: {
    showInfo(event) {
      console.log(2222);
      console.log(event);
      this.formItem = event;
      this.modelState = true;
    },

    // changeMenu(event) {
    //   this.removeCovering();
    //   this.$nextTick(() => {
    //     if (event) {
    //       switch (event.id) {
    //         case 1:
    //           this.drawCylinder(wcData, event);
    //           //智慧厕所
    //           break;
    //         case 2:
    //           this.drawCylinder(marketData, event);
    //           //农贸市场
    //           break;
    //         case 3:
    //           this.drawCylinder(rubbishData, event);
    //           //垃圾处理
    //           break;
    //         case 4:
    //           this.drawCylinder(sewageData, event);
    //           //污水处理
    //           break;
    //         case 10:
    //           this.drawCylinder(waterData, event);
    //           break;
    //         case 11:
    //           this.drawCylinder(homeData, event);
    //           break;
    //         case 12:
    //           this.drawCylinder(schoolData, event);
    //           break;

    //         default:
    //           break;
    //       }
    //     }
    //   });
    // },
    //绘制地图
    drawMap() {
      var that = this;
      const opts = {
        subdistrict: 0, //图层分区
        extensions: "all", //扩张
        level: "district", //行政等级县级市
      };
      var map;
      var district = new AMap.DistrictSearch(opts);
      // debugger
      district.search("菏泽市", function (status, result) {
        // console.log(result);
        const mapData = result.districtList[0];
        const bounds = mapData.boundaries;
        var mask = [];
        for (var i = 0; i < bounds.length; i += 1) {
          mask.push([bounds[i]]);
        }
        map = new AMap.Map("TenChong", {
          //mask: mask,
          center: [mapData.center.lng, mapData.center.lat], //聚焦点经纬度
          disableSocket: true, //开启位置
          viewMode: "3D", //地图模式
          showLabel: true, //展示地名
          labelzIndex: 200, //层级
          pitch: 55, //偏移量
          zoom: 9.3, //放大倍数
          rotation: 50, //旋转
          showBuildingBlock: true, //展示建筑物
          mapStyle: "amap://styles/darkblue", //地图样式
          // mapStyle: "amap://styles/light",

          layers: [
            // 道路系统
            // new AMap.TileLayer.RoadNet({
            //     rejectMapMask: true,
            //     // mapStyle: 'amap://styles/grey',
            // }),
            // 卫星系统
            // new AMap.TileLayer.Satellite()
          ],
        });

        // var icon = new AMap.Icon({
        //   size: new AMap.Size(40, 50), // 图标尺寸
        //   image: require("../../../public/img/icons/mark.png"), // Icon的图像
        //   // imageOffset: new AMap.Pixel(0, -60), // 图像相对展示区域的偏移量，适于雪碧图等
        //   // imageSize: new AMap.Size(40, 50), // 根据所设置的大小拉伸或压缩图片
        // });

        // var marker1 = new AMap.Marker({
        //   position: new AMap.LngLat(115.48, 34.88), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        //   title: "曹县",
        //   icon: require("../../../public/img/icons/mark.png"),
        // });

        // var marker2 = new AMap.Marker({
        //   position: new AMap.LngLat(115.5, 35.13), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        //   title: "定陶区",
        //   icon: require("../../../public/img/icons/mark2.png"),
        // });

        // var markList = [marker1,marker2]

        // 将创建的点标记添加到已有的地图实例：
        map.add(that.geoList);
        // console.log(that.geoList);

        map.on("zoomchange", (e) => {
          console.log(e);
          if (e.target.getZoom() > 16) {
            that.showMarker = 1;
          } else {
            that.showMarker = 0;
          }
        });
        that.mapObj = map;
        window.mapObj = map;
        //添加描边
        for (var i = 0; i < bounds.length; i += 1) {
          new AMap.Polyline({
            path: bounds[i],
            strokeColor: "#07b8e6",
            strokeWeight: 4,
            map: map,
          });
        }

        //视角控制器
        // map.plugin(["AMap.ControlBar"], function () {
        //   var controlBar = new AMap.ControlBar({
        //     position: {
        //       bottom: "16px",
        //       right: "16px",
        //     },
        //   });
        //   map.addControl(controlBar);
        // });

        //地图可视化对象
        var loca = new Loca.Container({
          map,
        });
        that.mapLoca = loca;
        //绘制楼快
        var geo = new Loca.GeoJSONSource({
          //data: hangzhouData,
          data: hezeData,
        });
        var px = new Loca.PolygonLayer({
          loca,
          zIndex: 120,
          opacity: 1,
          cullface: "none",
          shininess: 30,
          hasSide: true,
        });
        px.setSource(geo);
        px.setStyle({
          zIndex: 10,
          height: -4500,
          bottomColor: "rgba(80,192,255,0.25)",
          topColor: "rgba(12, 11, 73,1)",
          sideTopColor: "rgba(145, 213, 229,1)",
          sideBottomColor: "rgba(7, 184, 230,1)",
          // sideBottomColor: 'rgba(255,255,255,0.75)',
          altitude: -100,
        });
        loca.add(px);
        // that.play();
      });
    },
    //绘制圆柱标点
    drawCylinder(jsonData, item) {
      var loca = this.mapLoca;
      var map = this.mapObj;
      var geo = new Loca.GeoJSONSource({
        data: jsonData,
      });
      // 浮动三角
      var triangleZMarker = new Loca.ZMarkerLayer({
        loca: loca,
        zIndex: 999,
        zooms: [10, 20],
      });
      triangleZMarker.setSource(geo);
      // debugger
      triangleZMarker.setStyle({
        content: (i, feat) => {
          // debugger
          if (feat.properties.status === 1) {
            console.log(666666666666666666);
            return (
              '<div><img style="width:40px; height:40px;" src="' +
              item.url1 +
              '" alt="" srcset=""></div>'
            );
          } else if (feat.properties.status === 2) {
            console.log(333333333);
            return (
              '<div><img style="width:40px; height:40px;" src="' +
              item.url2 +
              '" alt="" srcset=""></div>'
            );
          }
        },
        // unit: 'meter',
        rotation: 0,
        alwaysFront: true,
        size: [40, 40],
        altitude: 20,
      });
      // triangleZMarker.addAnimate({
      //     key: "altitude",
      //     value: [0, 1],
      //     zooms: [40, 40],
      //     random: true,
      //     transform: 1000,
      //     delay: 2000,
      //     yoyo: true,
      //     repeat: 999999,
      // });
      //添加点击气泡文本标记
      var text = new AMap.Text({
        text: "纯文本标记",
        anchor: "center", // 设置文本标记锚点
        draggable: true,
        cursor: "pointer",
        angle: 0,
        visible: false,
        offset: [0, -110],
        style: {
          width: "300px",
          height: "auto",
          "border-radius": "5px",
          "background-color": "rgb(9, 18, 45,0.75)",
          "box-shadow": "inset 0 0 3px #253954",
          border: "1px solid #253954",
          padding: "0px",
          "z-index": "999",
        },
      });
      text.setMap(map);
      //鼠标悬浮事件
      map.on("mousemove", function (e) {
        var selectPoint = triangleZMarker.queryFeature(e.pixel.toArray());
        // console.log(selectPoint);
        if (selectPoint) {
          text.show();
          text.setText(
            '<div style="padding: 5px 10px;font-size: 14px;color: #c1c7c9;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">' +
              selectPoint.properties.name +
              "<div>" +
              '<div style="display: flex; padding: 5px">' +
              '<img style="width: 140px; height: 112px; border-radius: 3px" src="' +
              selectPoint.properties.image +
              '" alt="" srcset="" />' +
              '<div style="width: 130px; margin-left: 10px">' +
              '<p style="font-size: 13px; color: #c1c7c9">计划投资</p>' +
              '<div style="font-size: 12px; color: #3cbbb0">' +
              selectPoint.properties.invest +
              "元</div>" +
              '<p style="font-size: 13px; color: #c1c7c9">项目地址</p>' +
              '<div style="font-size: 12px; color: #3cbbb0">' +
              selectPoint.properties.address +
              "</div>" +
              '<p style="font-size: 13px; color: #c1c7c9">项目状态</p>' +
              '<div style="font-size: 12px; color: #3cbbb0">建设中</div>' +
              "</div>" +
              "</div>"
          );
          text.setPosition(e.lnglat);
        } else {
          text.hide();
        }
      });
      //鼠标点击事件
      var that = this;
      map.on("click", function (e) {
        text.hide();
        var selectPoint = triangleZMarker.queryFeature(e.pixel.toArray());
        // console.log(selectPoint);
        if (selectPoint) {
          that.showInfo(selectPoint.properties);
        } else {
        }
      });
      // 呼吸点成功
      var scatterYellow = new Loca.ScatterLayer({
        loca,
        zIndex: 110,
        opacity: 1,
        visible: true,
        zooms: [10, 20],
        depth: false,
      });
      scatterYellow.setSource(geo);
      scatterYellow.setStyle({
        unit: "px",
        size: function (i, feat) {
          if (feat.properties.status === 1) {
            return [40, 40];
          } else if (feat.properties.status === 2) {
            return [0, 0];
          }
        },
        texture:
          "http://dianimage.hiyouj.com/db48cedb8c7742268e271c64babc5f2a.png",
        altitude: 20,
        duration: 2000,
        animate: false,
      });
      //失败呼吸点
      var scatterBlue = new Loca.ScatterLayer({
        loca,
        zIndex: 110,
        opacity: 1,
        visible: true,
        zooms: [10, 20],
        depth: false,
      });
      scatterBlue.setSource(geo);
      scatterBlue.setStyle({
        unit: "px",
        size: function (i, feat) {
          if (feat.properties.status === 2) {
            return [40, 40];
          } else if (feat.properties.status === 1) {
            return [0, 0];
          }
        },
        texture:
          "http://dianimage.hiyouj.com/fa276d34628a42bdb94cfd6395caa6d5.png",
        altitude: 20,
        duration: 2000,
        animate: false,
      });
      this.scatterBlue = scatterBlue;
      this.triangleZMarker = triangleZMarker;
      this.scatterYellow = scatterYellow;
      loca.animate.start();
    },

    //绘制地图标点
    getMark() {
      this.$get("/publicity/getAllMarget").then((res) => {
        if (res.result) {
          res.result.forEach((item, index) => {
            if (item.deptType === 4) {
              this.geoList.push(
                new AMap.Marker({
                  position: new AMap.LngLat(item.longitude, item.latitude),
                  title: item.deptName,
                  icon: require("../../../public/img/icons/mark.png"),
                })
              );
            } else if (item.deptType === 7) {
              this.geoList.push(
                new AMap.Marker({
                  position: new AMap.LngLat(item.longitude, item.latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                  title: item.deptName,
                  icon: require("../../../public/img/icons/mark2.png"),
                })
              );
            }
          });
        }
        console.log(this.geoList);
        // console.log(res.result);
      });
    },

    //移除覆盖物
    // removeCovering() {
    // var loca = this.mapLoca;
    // if (this.triangleZMarker) {
    //   console.log("111");
    //   console.log(this.triangleZMarker);
    //   loca.remove(this.triangleZMarker);
    // }
    // if (this.scatterBlue) {
    //   console.log("222");
    //   loca.remove(this.scatterBlue);
    // }
    // if (this.scatterYellow) {
    //   console.log("3333");
    //   loca.remove(this.scatterYellow);
    // }
    // },
    // play() {
    //   var loca = this.mapLoca;
    //   var deg = 0;
    //   // setInterval(() => {
    //   //   deg += 0.1;
    //   //   loca.setRotation(deg);
    //   // }, 300);
    // },
  },
  //在实例初始化之后，组件属性计算之前，如data属性等
  beforeCreate() {},
  //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
  created() {},
  //在挂载开始之前被调用：相关的 render 函数首次被调用。
  beforeMount() {},
  //挂载后
  mounted() {
    this.getMark();

    this.drawMap();
  },
  //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
  beforeUpdate() {},
  //数据更新时后调用
  updated() {},
  // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
  activated() {},
  // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
  deactivated() {},
  // 实例销毁之前调用。在这一步，实例仍然完全可用。
  beforeDestroy() {},
  //Vue 实例销毁后调用。
  destroyed() {},
};
</script> 

<style lang="scss">
.marker {
  width: 33px;
  height: 20px;
  background-image: url("../../assets/images/66.png");
  background-size: 33px 20px;
}
.marker-text {
  display: inline-block;
  font-size: 12px;
  width: 150px;
  border-radius: 10px;
  color: rgba($color: #fff, $alpha: 0.5);
  text-align: left;
}
.marker-desc {
  font-size: 12px;
  width: 150px;

  color: rgba($color: #fff, $alpha: 0.5);
  text-align: left;
}
.marker-img {
  width: 33px;
  height: 20px;
}
.loading {
  font-size: 40px;
  color: #fff;
}
#visualization {
  .bottom-menu {
    position: fixed;
    bottom: 1.7vh;
    left: 28.8vw;
    width: 42.5vw;
    height: auto;
    overflow: hidden;
  }

  .nav-container {
    // border:1px solid red;
    position: fixed;
    height: auto;
    width: 100vw;
    z-index: 999;
  }

  .map-container {
    width: 100vw;
    height: 100vh;
  }

  .card-container {
    position: fixed;
    width: 22vw;
    height: auto;
    top: 8vh;
    left: 1vw;
    // border: 1px solid red;
  }

  .item-container {
    // border: 1px solid red;
    height: auto;
    // border: 1px solid red;
    height: 29.5vh;
  }

  .item-title {
    border-bottom: 1px solid #314b6d;
    padding: 0px 10px;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    span {
      color: #c1c7c9;
      font-size: 14px;
    }
  }

  .plan-container {
    padding: 15px;
    height: auto;
  }

  .center-bottom-container {
    position: fixed;
    bottom: 2.8vh;
    width: 48vw;
    height: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .roll-container {
    position: fixed;
    // border: 1px solid red;
    top: 4.5vh;
    left: 26vw;
    width: 48vw;
    height: auto;
  }

  .subentry-container {
    position: fixed;
    width: 22vw;
    height: 90vh;
    top: 8vh;
    right: 1vw;

    .price-container {
      height: 220px;
      // border: 1px solid red;
    }

    .word-container {
      height: auto;
      padding: 15px 15px;
      display: flex;
      justify-content: space-between;

      .word-item {
        width: 33%;
        // border: 1px solid red;
        margin-top: 20px;

        .bg1 {
          background: url("../../assets/images/pillars1.png");
        }

        .bg2 {
          background: url("../../assets/images/pillars2.png");
        }

        .bg3 {
          background: url("../../assets/images/pillars3.png");
        }

        .word-img {
          width: 60px;
          height: 110px;
          text-align: center;

          background-size: cover;
          margin: auto;
          margin-bottom: 5px;

          img {
            width: 50px;
            margin-top: 40px;
          }
        }

        p {
          text-align: center;
          color: #c1c7c9;
          font-size: 13px;

          span {
            color: #07b9e6;
            font-size: 18px;
            margin-right: 5px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
