<template>
    <Panel class="item-container uk-animation-slide-right">
            <template slot="outer">
                 <dv-loading class="loading" v-if="!isShow">正在加载中...</dv-loading>
                <div class="item-title">
                    <span>接入机构情况</span>
                    <span>
                        <Icon type="ios-resize" size="18" />
                    </span>
                </div>
                <div  v-if="isShow" class="general-container">
                    <div class="general-left">
                        <dv-charts :option="options[0]" />
                        <div>总接入</div>
                         <div>{{totalCount}}所</div>
                    </div>
                    <div class="general-left">
                        <dv-charts :option="options[1]" />
                        <div>学校接入</div>
                        <div>{{school}}所</div>
                    </div>
                    <div class="general-left">
                        <dv-charts :option="options[2]" />
                        <div>市场接入</div>
                        <div>{{store}}所</div>
                    </div>
                    
                </div>
            </template>
        </Panel>
</template>
<script>
import {
    Panel,
} from "../index.js";
export default {
    components:{Panel},
    created(){
        this.$get('/publicity/getAccessDept?deptId=2&deptType=4%2C7').then(res=>{
            console.log("getAccessDept",res)
            let tmpOptions = this._.cloneDeep(this.options)
            this.totalCount = res.result.deptAccessTotalAccount
            this.store = res.result.deptTypeAccessList[0].deptCount
            if (res.result.deptTypeAccessList[1]) {
                this.school = res.result.deptTypeAccessList[1].deptCount
            }
            tmpOptions[0].series[0].data[0].value =  100
            tmpOptions[0].series[0].data[0].count =  res.result.deptAccessTotalAccount
            tmpOptions[1].series[0].data[0].value =  (res.result.deptTypeAccessList[0].deptCount/res.result.deptAccessTotalAccount)*100
            tmpOptions[1].series[0].data[0].value =  (res.result.deptTypeAccessList[0].deptCount/res.result.deptAccessTotalAccount)*100
            //tmpOptions[2].series[0].data[0].value =  (res.result.deptTypeAccessList[1].deptCount/res.result.deptAccessTotalAccount)*100
            this.options = []
            for(let i=0;i<tmpOptions.length;i++){
                this.options.push(tmpOptions[i])
               
            }
            this.isShow = true
            console.log(this.options)
        })
    },
    data(){
        return{
            isShow:false,
            totalCount:0,
            school:0,
            store:0,
            options:[
                {
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      data: [
        { name: 'itemA', value: 65,count:65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      radius:"80%",
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter:'{value}%',
        style: {
            fill: '#1ed3e5',
            fontSize: 14
        }
      }
    }
  ]
},
{
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      data: [
        { name: 'itemA', value: 65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      radius:"80%",
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter :'{value}%',
        style: {
            fill: '#1ed3e5',
            
            fontSize: 14
        }
      }
    }
  ]
},
{
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      data: [
        { name: 'itemA', value: 65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      radius:"80%",
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter: '{value}%',
        style: {
            fill: '#1ed3e5',
            fontSize: 14
        }
      }
    }
  ]
}
            ]
        }
    }
}
</script>
<style scoped lang="scss">
    .item-container{
        height: 22vh !important;
    }
.general-container {
        // border:1px solid red;
        height: auto;
        padding: 15px 15px 5px 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        color:#fff;
        .general-left {
            // border:1px solid red;
            // width: 10;
            // height: 100vh;
            //align-items: center;
            flex:1;
            height: 10vh;

        }

        .general-item {
            // border:1px solid red;
            height: auto;
            border: 1px solid #373d50;
            border-radius: 3px;
            padding: 2px;
            margin-bottom: 10px;
            margin-top: 15px;

            .item-box {
                background: #373d50;
                width: 100%;
                height: 100%;
                padding: 7px 5px;
                color: #c1c7c9;
                font-size: 13px;
                display: flex;
                justify-content: space-between;

            }

            .item-num {
                color: #07b9e6;
                margin-right: 5px;
                font-size: 14px;
                font-weight: 'din';
            }
        }

        .general-center {
            // border:1px solid red;
            width: 40%;
        }
    }
</style>