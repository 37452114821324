<!--
  功能：导航栏
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2022年01月13日 10:22:30
-->
<template>
<div id="navigation">
    <p class="nav-title">菏泽市食安检测大数据监管平台</p>
    <!-- <p class="nva-time">项目总周期: 2022-01-22  至  2023-01-23  (356天)</p> -->
    <!-- <div class="nva-user">
        <Dropdown>
        <span>
            项目总览
            <Icon type="ios-arrow-down"></Icon>
        </span>
        <DropdownMenu slot="list">
            <DropdownItem>智慧厕所</DropdownItem>
            <DropdownItem>农贸市场</DropdownItem>
            <DropdownItem>饮水工程</DropdownItem>
            <DropdownItem divided>马拉松</DropdownItem>
        </DropdownMenu>
    </Dropdown>
        <Icon type="md-settings" size="20" style="margin:0px 8px;" />
        <Icon type="ios-contact" size="20" />
    </div> -->
</div>
</template>

<script>
export default {
    // 组件名称
    name: 'Navigation',
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {

        }
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {},
    //在实例初始化之后，组件属性计算之前，如data属性等
    beforeCreate() {},
    //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    created() {},
    //在挂载开始之前被调用：相关的 render 函数首次被调用。
    beforeMount() {},
    //挂载后
    mounted() {},
    //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
    beforeUpdate() {},
    //数据更新时后调用
    updated() {},
    // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
    activated() {},
    // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
    deactivated() {},
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
    beforeDestroy() {},
    //Vue 实例销毁后调用。
    destroyed() {}
}
</script> 

<style lang="scss" scoped>
#navigation {
    background: url("../../../../assets/images/title.png");
    background-size: 100vw 7vh;
    height: 7vh;
    width: 100vw;
    padding: 0px;
    // border: 1px solid red;
    position: relative;

    //  background: #042249;
    .nav-title {
        color: #07b9e6;
        text-align: center;
        font-size: 1.45vw;
        margin-top: 0px;
        line-height: 5.2vh;
        font-weight: 530;
    }

    .nva-time {
        position: absolute;
        // border: 1px solid red;
        color: #07b9e6;
        top: 3.1vh;
        left: 6vw;
        font-size: 15px;
        font-weight: 500;

    }

    .nva-user {
        position: absolute;
        // border: 1px solid red;
        color: #07b9e6;
        top: 3.1vh;
        right: 6vw;
        font-size: 15px;
        font-weight: 500;
    }
}
</style>
