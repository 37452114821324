<!--
  功能：项目进度
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2021年12月07日 15:57:58
-->
<template>
<div class="plan-card">
    <div ref="pieChart" class="plan-box"></div>
    <div class="number">
        <div class="number-text">597465</div>
        <div class="number-title">实际使用(元)</div>
    </div>
    <div class="number-right">
        <div class="number-text">297664</div>
        <div class="number-title">实际余额(元)</div>
    </div>
</div>
</template>

<script>
//挂载百度echarts
import * as echarts from "echarts";
export default {
    // 组件名称
    name: "demo",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            myChart: null,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        //绘制图表
        drawChart() {
            this.myChart = echarts.init(this.$refs.pieChart);

            var color = ['#fb734e', '#e32f46', '#94d96c', '#0bbcb7', '#1a9bfc', '#7049f0'];
            var dataStyle = {
                normal: {
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    shadowBlur: 40,
                    borderWidth: 10,
                    shadowColor: 'rgba(0, 0, 0, 0)' //边框阴影
                }
            };
            var placeHolderStyle = {
                normal: {
                    color: '#393d50',
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    }
                },
                emphasis: {
                    color: '#393d50'
                }
            };
            var option = {
                title: {
                    text: '实际投入',
                    subtext: 57419,
                    x: 'center',
                    y: 'center',
                    textStyle: {
                        fontWeight: 'normal',
                        fontSize: 10,
                        color: "#c1c7c9",
                    },
                    subtextStyle: {
                        fontWeight: '600',
                        fontSize: 16,
                        color: "#c1c7c9",
                    }
                },
                tooltip: {
                    trigger: 'item',
                    show: true,
                    formatter: "{b} : <br/>{d}%",
                    backgroundColor: '#ffffff', // 背景
                    padding: [5, 8], //内边距
                    extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影

                },
                legend: {
                    top: '10',
                    left: '10',
                    data: ['计划投入', '实际投入'],
                    textStyle: {
                        color: "#c1c7c9",
                        fontSize: 12
                    },
                    itemWidth: 20,
                    itemHeight: 12,

                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '2%',
                    containLabel: true
                },
                series: [{
                        name: 'Line 1',
                        type: 'pie',
                        clockWise: false,
                        radius: [55, 65],
                        center: ['50%', '55%'],
                        itemStyle: dataStyle,
                        hoverAnimation: false,
                        startAngle: 90,
                        label: {
                            borderRadius: '10',
                        },
                        data: [{
                                value: 54.6,
                                name: '计划投入',
                                itemStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                            offset: 0,
                                            color: color[4]
                                        }, {
                                            offset: 1,
                                            color: color[5]
                                        }]),
                                    }
                                }
                            },
                            {
                                value: 45.4,
                                name: '',
                                tooltip: {
                                    show: false
                                },
                                itemStyle: placeHolderStyle
                            },
                        ]
                    },
                    {
                        name: 'Line 2',
                        type: 'pie',
                        clockWise: false,
                        radius: [35, 45],
                        center: ['50%', '55%'],
                        itemStyle: dataStyle,
                        hoverAnimation: false,
                        startAngle: 90,
                        data: [{
                                value: 56.7,
                                name: '实际投入',
                                itemStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                            offset: 0,
                                            color: "#00ff99"
                                        }, {
                                            offset: 1,
                                            color: color[3]
                                        }])
                                    }
                                }
                            },
                            {
                                value: 43.3,
                                name: '',
                                tooltip: {
                                    show: false
                                },
                                itemStyle: placeHolderStyle
                            },
                        ]
                    }
                ]
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                // 执行  图表大小随窗口大小改变而改变
                this.myChart.resize();
            });
        },
    },
    //在实例初始化之后，组件属性计算之前，如data属性等
    beforeCreate() {},
    //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    created() {},
    //在挂载开始之前被调用：相关的 render 函数首次被调用。
    beforeMount() {},
    //挂载后
    mounted() {
        this.drawChart();
    },
    //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
    beforeUpdate() {},
    //数据更新时后调用
    updated() {},
    // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
    activated() {},
    // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
    deactivated() {},
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
    beforeDestroy() {},
    //Vue 实例销毁后调用。
    destroyed() {},
};
</script> 

<style lang="scss" scoped>
.plan-card {
    padding: 0px;
    position: relative;
    .plan-box {
        height: 250px;
        width: 99%;
        margin: auto;
    }
    .number{
        position: absolute;
        top:40%;
        left:15px;
        text-align: center;
        .number-text {
            color:#20c0f5;
            font-size: 20px;
        }
        .number-title{
            color:#c1c7c9;
            font-size: 13px;
        }
    }
    .number-right{
        position: absolute;
        top:40%;
        right:15px;
        text-align: center;
        .number-text {
            color:#20c0f5;
            font-size: 20px;
        }
        .number-title{
            color:#c1c7c9;
            font-size: 13px;
        }
    }
}
</style>
