<!--
  功能：项目进度
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2021年12月07日 15:57:58
-->
<template>
<div class="plan-card">
    <div ref="wordChart" id="chart-panel" class="plan-box"></div>
</div>
</template>

<script>
//挂载百度echarts
import * as echarts from "echarts";
import "echarts-wordcloud";
export default {
    // 组件名称
    name: "demo",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            myChart: null,
            wordList: [{
                    name: "2021年5月项目启动",
                    value: 15000,
                },
                {
                    name: "2023年5月项目竣工",
                    value: 10081,
                },
                {
                    name: "垃圾房130个建设",
                    value: 9386,
                },
                {
                    name: "饮水工程100个",
                    value: 7500,
                },
                {
                    name: "生猪屠宰场200个",
                    value: 7500,
                },
                {
                    name: "智慧社区",
                    value: 6500,
                },
                {
                    name: "乡村振兴",
                    value: 6500,
                },
                {
                    name: "河道治理20千米",
                    value: 6000,
                },
                {
                    name: "马拉松200千米",
                    value: 4500,
                },
                {
                    name: "总投资20亿",
                    value: 3800,
                },
                {
                    name: "覆盖乡镇18个",
                    value: 3000,
                },
                {
                    name: "厕所革命200个",
                    value: 2500,
                },
                {
                    name: "腾冲市",
                    value: 2300,
                },
            ]
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        //绘制图表
        drawChart() {
            this.myChart = echarts.init(this.$refs.wordChart);
             var textColor=[
                "#00f1ff","#0727c5","#83661e","#10007f","#1890ff","5c617a"
            ];
            var option = {
                series: [{
                    type: "wordCloud",
                    //用来调整词之间的距离
                    gridSize: 20,
                    //用来调整字的大小范围
                    // Text size range which the value in data will be mapped to.
                    // Default to have minimum 12px and maximum 60px size.
                    sizeRange: [12, 30],
                    // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
                    //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
                    // rotationRange: [-45, 0, 45, 90],
                    // rotationRange: [ 0,90],
                    rotationRange: [0, 0],
                   
                    //随机生成字体颜色
                    // maskImage: maskImage,
                    textStyle: {
                        color: function () {
                            let size = textColor.length-1;
                            let index = parseInt(size*Math.random());
                            return textColor[index];
                        },
                    },
                    //位置相关设置
                    // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
                    // Default to be put in the center and has 75% x 80% size.
                    left: "center",
                    top: "center",
                    right: null,
                    bottom: null,
                    width: "100%",
                    height: "100%",
                    //数据
                    data: this.wordList,
                }, ]
            }
            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                // 执行  图表大小随窗口大小改变而改变
                this.myChart.resize();
            });
        },
    },
    //在实例初始化之后，组件属性计算之前，如data属性等
    beforeCreate() {},
    //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    created() {},
    //在挂载开始之前被调用：相关的 render 函数首次被调用。
    beforeMount() {},
    //挂载后
    mounted() {
        this.drawChart();
    },
    //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
    beforeUpdate() {},
    //数据更新时后调用
    updated() {},
    // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
    activated() {},
    // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
    deactivated() {},
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
    beforeDestroy() {},
    //Vue 实例销毁后调用。
    destroyed() {},
};
</script> 

<style lang="scss" scoped>
.plan-card {
    padding: 0px;

    .plan-box {
        height: 320px;
        width: 99%;
        margin: auto;
    }
}
</style>
