<template>
<div>
    <div class="footer-menu" ref="personWrap">
        <div class="footer-item" ref="personTab">
            <div class="menu-item uk-animation-scale-up" :class="{active:index == item.id}" v-for="item in classList" :key="item.id" ref="personItem" @click="selectClass(item)">
                <p>{{item.name}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import BScroll from "better-scroll";
export default {
    name: "BottomMenu",
    data() {
        return {
            index: 1,
            classList: [{
                    id: 1,
                    name: "智慧厕所",
                    img: require("@/assets/images/svg/toilet.svg")
                },
                {
                    id: 2,
                    name: "农贸市场",
                    img: require("@/assets/images/svg/market.svg")
                },
                {
                    id: 3,
                    name: "垃圾处理",
                    img: require("@/assets/images/svg/garbage.svg")
                },
                {
                    id: 4,
                    name: "污水处理",
                    img: require("@/assets/images/svg/sewage.svg")
                },
                {
                    id: 5,
                    name: "马拉松",
                    img: require("@/assets/images/svg/marathon.svg")
                },
                {
                    id: 6,
                    name: "屠宰场",
                    img: require("@/assets/images/svg/demo.svg")
                },
                {
                    id: 7,
                    name: "乡村公路",
                    img: require("@/assets/images/svg/highway.svg")
                },
                {
                    id: 8,
                    name: "环境整治",
                    img: require("@/assets/images/svg/environment.svg")
                },
                {
                    id: 9,
                    name: "河道治理",
                    img: require("@/assets/images/svg/riverway.svg")
                },
                {
                    id: 10,
                    name: "饮水工程",
                    img: require("@/assets/images/svg/water.svg")
                },
                {
                    id: 11,
                    name: "智慧民宿",
                    img: require("@/assets/images/svg/hotel.svg")
                },
                {
                    id: 12,
                    name: "智慧学校",
                    img: require("@/assets/images/svg/school.svg")
                },
                {
                    id: 13,
                    name: "乡村旅游",
                    img: require("@/assets/images/svg/travel.svg")
                },
                {
                    id: 14,
                    name: "扶贫车间",
                    img: require("@/assets/images/svg/poverty.svg")
                },
            ],
        };
    },
    created() {
        this.$nextTick(() => {
            this.personScroll();
        });
    },
    methods: {
        selectClass(event){
            this.index = event.id;
        },
        personScroll() {
            // 默认有六个li子元素，每个子元素的宽度为120px
            let width = this.classList.length * 8;
            console.log(this.$refs.personItem.style);
            this.$refs.personTab.style.width = width + "vw";
            // this.$nextTick 是一个异步函数，为了确保 DOM 已经渲染
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.personWrap, {
                        startX: 0,
                        click: true,
                        scrollX: true,
                        // 忽略竖直方向的滚动
                        scrollY: false,
                        eventPassthrough: "vertical"
                    });
                } else {
                    this.scroll.refresh();
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-menu {
    height: auto;
    overflow: hidden;

    .footer-item {
        display: flex;
        justify-content: flex-start;
        // width: 50vw;
    }

    // overflow-x:auto;
    .menu-item {
        width: 8vw;
        height: auto;
        text-align: center;
        flex-shrink: 0;
        color: #f5f7f9;
        padding: 8px 10px;
        margin-right: 0.6vw;
        font-weight: 500;
        font-size: 15px;
        overflow: hidden;
        background: linear-gradient(to left, #07b9e6, #07b9e6) left top no-repeat,
            linear-gradient(to bottom, #07b9e6, #07b9e6) left top no-repeat,
            linear-gradient(to left, #07b9e6, #07b9e6) right top no-repeat,
            linear-gradient(to bottom, #07b9e6, #07b9e6) right top no-repeat,
            linear-gradient(to left, #07b9e6, #07b9e6) left bottom no-repeat,
            linear-gradient(to bottom, #07b9e6, #07b9e6) left bottom no-repeat,
            linear-gradient(to left, #07b9e6, #07b9e6) right bottom no-repeat,
            linear-gradient(to left, #07b9e6, #07b9e6) right bottom no-repeat;
        background-size: 2px 25px, 25px 2px, 2px 25px, 25px 2px;
        background-color: rgb(9, 18, 45,0.9);
        // background-image: linear-gradient( 135deg, #97ABFF 10%, #123597 100%);
        box-shadow: inset 0 0 3px #253954;
        border: 1px solid #253954;
    }
    .active{
        color: #07b9e6;
        font-weight: 600;
    }
}
</style>
