<template>
<div>
    <div class="footer-menu" ref="personWrap">
        <div class="footer-item" ref="personTab">
            <div class="menu-item uk-animation-scale-up" v-for="item in classList" :key="item.id" ref="personItem" @click="selectClass(item)">
                <div class="menu-img">
                    <img :src="item.img" alt="" srcset="">
                </div>
                <p>{{item.name}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import BScroll from "better-scroll";
export default {
    name: "BottomMenu",
    data() {
        return {
            classList: [
                {
                    id: 2,
                    name: "农贸市场",
                    img: require("@/assets/images/svg/home12.png"),
                    url1:'http://dianimage.hiyouj.com/18ea5da906a84fe9a3cc82d956adddd4.png',
                    url2: 'http://dianimage.hiyouj.com/6c2fa3dceb3f49ca9f1364547e8ee495.png',
                    url3: ''
                },
                {
                    id: 10,
                    name: "人饮巩固",
                    img: require("@/assets/images/svg/home5.png"),
                    url1:'http://dianimage.hiyouj.com/157c5a4e060643efaa41426f99de9117.png',//已完成
                    url2: 'http://dianimage.hiyouj.com/c1ada6528d744b22be3c15af1599867c.png',//未完成
                    url3: 'http://dianimage.hiyouj.com/dda7e864e99e4de3aa38dbad9cede46d.png' //未开始
                },
                {
                    id: 3,
                    name: "垃圾处理",
                    img: require("@/assets/images/svg/home11.png"),
                    url1: 'http://dianimage.hiyouj.com/da9cc3a642114e2db39fcf124bcbc6fa.png',
                    url2: 'http://dianimage.hiyouj.com/86ab7b81d1fc434a9f62e3abd3d06d14.png',
                    url3: ''
                },
                {
                    id: 1,
                    name: "厕所革命",
                    img: require("@/assets/images/svg/home13.png"),
                    url1: 'http://dianimage.hiyouj.com/f7475f72ea8f45f681c3c30484e5736a.png',//已完成
                    url2: 'http://dianimage.hiyouj.com/85061f75d09d4011be94fde7506fa5a6.png',
                    url3: ''
                },
                {
                    id: 11,
                    name: "智慧民宿",
                    img: require("@/assets/images/svg/home4.png"),
                    url1:'http://dianimage.hiyouj.com/127da96f6b3147dea6757764660cd8b9.png',
                    url2: 'http://dianimage.hiyouj.com/5e3505f6658c4311903d5ae9d99d4e47.png',
                    url3: ''
                },
                {
                    id: 4,
                    name: "污水处理",
                    img: require("@/assets/images/svg/home10.png"),
                    url1:'http://dianimage.hiyouj.com/48b31d66245a49b39c57d10c6fdcb0d0.png',
                    url2: 'http://dianimage.hiyouj.com/48b31d66245a49b39c57d10c6fdcb0d0.png',
                    url3: ''
                },
                {
                    id: 12,
                    name: "智慧校园",
                    img: require("@/assets/images/svg/home3.png"),
                    url1:'http://dianimage.hiyouj.com/4903e73028ce452d87ec2c6ec830ae0d.png',
                    url2: '',
                    url3: ''
                },
                {
                    id: 5,
                    name: "马拉松",
                    img: require("@/assets/images/svg/home9.png"),
                    url1:'http://dianimage.hiyouj.com/195362a344544a8f9fcf5e1240d338e5.png',
                    url2: '',
                    url3: ''
                },
                {
                    id: 6,
                    name: "智慧屠宰",
                    img: require("@/assets/images/svg/home8.png"),
                    url1:'http://dianimage.hiyouj.com/9213371043a94a528c7ed7f92d45c83d.png',
                    url2: '',
                    url3: ''
                },
                {
                    id: 7,
                    name: "乡村公路",
                    img: require("@/assets/images/svg/home0.png"),
                    url1:'http://dianimage.hiyouj.com/d1ea7e2b20cc43d09f7479969720fb6d.png',
                    url2: '',
                    url3: ''
                },
                {
                    id: 8,
                    name: "环境治理",
                    img: require("@/assets/images/svg/home7.png"),
                    url1:'http://dianimage.hiyouj.com/7879b3f28ff443af9d0dcc1a6345ce04.png',
                    url2: '',
                    url3: ''
                },
                {
                    id: 9,
                    name: "河道治理",
                    img: require("@/assets/images/svg/home6.png"),
                    url1:'http://dianimage.hiyouj.com/9d4d01c0de4045a4a5344dced1335f5f.png',
                    url2: '',
                    url3: ''
                },
                
                {
                    id: 13,
                    name: "乡村旅游",
                    img: require("@/assets/images/svg/home2.png"),
                    url1:'http://dianimage.hiyouj.com/c29508f6ccf4457f8a4ebd03e6aa14e3.png',
                    url2: '',
                    url3: ''
                },
                {
                    id: 14,
                    name: "扶贫车间",
                    img: require("@/assets/images/svg/home1.png"),
                    url1:'http://dianimage.hiyouj.com/7ef7ac180aaa446eb671dbdbfb63b92e.png',
                    url2: '',
                    url3: ''
                },
            ],
        };
    },
    created() {
        this.$nextTick(() => {
            this.personScroll();
        });
    },
    methods: {
        //选择数据
        selectClass(event){
           console.log(event);
           this.$emit("change",event);
        },
        //列表滚动
        personScroll() {
            // 默认有六个li子元素，每个子元素的宽度为120px
            let width = this.classList.length * 8;
            console.log(this.$refs.personItem.style);
            this.$refs.personTab.style.width = width + "vw";
            // this.$nextTick 是一个异步函数，为了确保 DOM 已经渲染
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.personWrap, {
                        startX: 0,
                        click: true,
                        scrollX: true,
                        // 忽略竖直方向的滚动
                        scrollY: false,
                        eventPassthrough: "vertical"
                    });
                } else {
                    this.scroll.refresh();
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-menu {
    height: auto;
    overflow: hidden;

    .footer-item {
        display: flex;
        justify-content: flex-start;
        // width: 50vw;
    }

    // overflow-x:auto;
    .menu-item {
        width: 8vw;
        height: auto;
        text-align: center;
        flex-shrink: 0;

        .menu-img {
            width: 100px;
            height: 100px;
            background: url("../../../../assets/images/square.png");
            background-size: cover;
            text-align: center;
            margin: auto;

            img {
                width: 35px;
                height: 35px;
                margin-top: 20px;
                // border: 1px solid red;
            }
        }

        p {
            font-size: 13px;
            color: #c1c7c9;
        }
    }
}
</style>
