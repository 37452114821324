<template>
    <Panel class="item-container uk-animation-slide-right" style="margin-top: 10px">
            <template slot="outer">
                 <dv-loading class="loading" v-if="!isShow">正在加载中...</dv-loading>
                <div class="item-title">
                    <span>近30天样品类型分布</span>
                    <span>
                        <Icon type="ios-resize" size="18" />
                    </span>
                </div>
                <div class="chart-task-container">
                    <dv-active-ring-chart :config="config" class="ring-chart" />
                    <div class="legend">
                        <div v-for="item in config.data" :key="item.name">
                            <span class="title">{{item.name}}</span>
                            <span class="count"> {{item.value||0}}次</span>
                        
                    </div>
                    </div>
                    
                </div>
            </template>
        </Panel>
</template>
<script>
import {
    Panel,
} from "../index.js";
export default {
    components:{Panel},
    created(){
        this.$get('/publicity/getFoodDistribution?deptId=2&deptType=4%2C7').then(res=>{
            console.log(res)
            let  tmpConfig = this._.cloneDeep(this.config)
            tmpConfig.data = []
            res.result.forEach(e=>{
                tmpConfig.data.push({
                    name:e.foodName,
                    value:e['全部']
                })
            })
            this.config = tmpConfig
            this.isShow = true
        })
    },
    data(){
        return{
            isShow:false,
            config:{
                // showOriginValue:true,
                radius:"80%",
                activeRadius:"90%",
  data: [
    {
      name: '周口',
      value: 55
    },
    {
      name: '南阳',
      value: 120
    },
    {
      name: '西峡',
      value: 78
    },
    {
      name: '驻马店',
      value: 66
    },
    {
      name: '新乡',
      value: 80
    }
  ],
  lineWidth: 10
}
        }
    }
}
</script>
<style scoped lang="scss">

    .chart-task-container{
        display: flex;
        justify-content: center;
        height: calc(29vh - 40px);
        align-items: center;
        // align-items: center;
    }
    .ring-chart{
        width: calc(29vh - 70px);
        height: calc(29vh - 70px);
    }
    .legend{
        width: 180px;
        overflow: auto;
       color: #fff;
    height: calc(29vh - 60px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    div{
         width: 130px;
         display: flex;
         
         justify-content: space-between;
         .title{
             width: 90px;
             overflow: auto;
             text-overflow: ellipsis;
             white-space: nowrap;
         }
         .count{
             text-align: right;
         }
    }
    }
</style>