<template>
    <dv-border-box-8 class="center-content uk-animation-slide-bottom">
         <dv-loading class="loading" v-if="!isShow">正在加载中...</dv-loading>
          <dv-scroll-board :config="configData" style="color:#c1c7c9; font-size:13px;" class="task-table" />
    </dv-border-box-8>
</template>
<script>
import {
    Panel,
} from "../index.js";
export default {
    components:{Panel},
    created(){
        this.$get('/publicity/listTimelyInspection?deptId=2&deptType=4%2C7').then(res=>{
            console.log(res)
            let  tmpConfig = this._.cloneDeep(this.configData)
            tmpConfig.data = []
            res.result.forEach(e=>{
                tmpConfig.data.push([e.sampleCreate,e.deptName,e.deptType,e.foodName,e.projectName,e.reportResult,e.sampleFollowUp])
            })
            this.configData = tmpConfig
            this.isShow = true
        })
    },
    data(){
        return{
            isShow:false,
          configData: {
        header: ["时间", "机构名称", "类型","样品","项目","结果","后续"],
        headerBGC: "#0e1e4e",
        headerHeight: 34,
        rowNum: 5,
        oddRowBGC: "#2a3042",
        evenRowBGC: "rgb(9, 18, 45)",
        columnWidth:[120,230,80,100,80],
        align:"center",
        data: [
        ],
      },
        }
    }
}
</script>
<style scoped lang="scss">
    .center-content{
        width: 48vw;
        height: 228px;
        display: flex;
        overflow: hidden;
        background-color: rgba(0,0,0,0.3);
        padding:4px;
    }
</style>