<template>

<div class="center-top uk-animation-slide-top">
    <dv-border-box-13 backgroundColor="rgba(0,0,0,0.5)" class="center-top-block">
      <div class="center-top-block-wrapper">
        <div>年度总检测数</div>
        <span>{{result.yearInspectionCount}}</span>
      </div>
    </dv-border-box-13>
    <dv-border-box-13 backgroundColor="rgba(0,0,0,0.5)" class="center-top-block">
      <div class="center-top-block-wrapper">
        <div>年度合格率</div>
        <span>{{result.yearPassRate}}</span>
      </div>
    </dv-border-box-13>
    <dv-border-box-13 backgroundColor="rgba(0,0,0,0.5)" class="center-top-block">
      <div class="center-top-block-wrapper">
        <div>年度不合格数量</div>
        <span>{{result.yearUnqualifiedNumber||0}}</span>
      </div>
    </dv-border-box-13>
  </div>
  
</template>
<script>
export default {
    created(){
        this.$get('/publicity/getInspectionAndDept?deptId=2&deptType=4%2C7').then(res=>{
            console.log(res)
            this.result = res.result
        })
    },
    data(){
        return {
            result:{}
        }
    }
};
</script>

<style lang="scss" scoped>
.center-top {
   
  height: 150px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
//   background-color: rgba($color: #000000, $alpha: 0.3);
  &-block {
    width: 200px;
    height: 120px;
    overflow: hidden;
    
    &-wrapper {
      overflow: hidden;
      width: 200px;
      height: 120px;
      flex-direction: column;
      color: #fff;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    //   background-color: rgba(0,0,0,0.5);
      div{
          padding:0 5px;
          border-radius: 10px;
          text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
      }
      span{
          color:#E6D513;
        //   background-color: rgba(0,0,0,0.5);
        //   padding:0 5px;
        //   border-radius: 5px 10px;
        //   margin-top: 5px;
      }
     
    }
    // background-color: rgba($color: #fff, $alpha: 0.5);
  }
}
</style>