<template>
  <Panel
    class="item-container uk-animation-slide-left"
    style="margin-top: 10px"
  >
    <template slot="outer">
      <div class="item-title">
        <span>任务达成趋势图</span>
        <span>
          <Icon type="ios-resize" size="18" />
        </span>
      </div>
      <div class="task-container">
        <dv-charts :option="option" />
      </div>
    </template>
  </Panel>
</template>
<script>
import { Panel } from "../index.js";
export default {
  components: { Panel },
  created(){
    this.$get("/publicity/getWeekDailyTaskTrend?deptId=2&deptType=4%2C7").then(res=>{
      console.log(res)
      let tmpOption = this._.cloneDeep(this.option)
      let _finishDateArray = []
      let _dailyTaskCountArray = []
      let _finishRateArray = []
      res.result.forEach((item,index)=>{
        _finishDateArray.push(item.finishDate)
        _dailyTaskCountArray.push(item.dailyTaskCount)
        _finishRateArray.push(parseFloat(item.finishRate))
      })
      tmpOption.xAxis.data = _finishDateArray
      tmpOption.series[0].data = _dailyTaskCountArray
      tmpOption.series[1].data = _finishRateArray
      this.option = tmpOption

    })
  },
  data() {
    return {
      option: {
        legend: {
          textStyle: {
            fill: "#fff",
          },
          data: [
            {
              name: "任务完成数量",
            },
            {
              name: "完成率",
            },
          ],
        },
        xAxis: {
          nameTextStyle: {
            fill: "#fff",
            fontSize: 10,
          },
          axisLabel: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: [
          {
            name: "完成率",
            data: "value",
            position: "right",
            max:1,
            mix:0,
            interval:0.2,
            axisLabel: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            nameTextStyle: {
              fill: "#fff",
              fontSize: 10,
            },
            splitLine: {
              show: false,
            },
          },
          {
            name: "任务完成数量",
            data: "value",
            min:0,
            nameTextStyle: {
              fill: "#fff",
              fontSize: 10,
            },
            axisLabel: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
          },
          
        ],
        series: [
          {
            name: "任务完成数量",
            data: [1000, 1200, 900, 1500, 900, 1200, 1000],
            type: "bar",
            smooth: true,
            label:{
              show:true,
            },
            lineArea: {
              show: true,
              gradient: ["rgba(225, 225, 225, 1)", "rgba(225, 225, 225, 0)"],
            },
            lineStyle: {
              stroke: "rgba(225, 225, 225, 1)",
              lineDash: [3, 3],
            },
            linePoint: {
              style: {
                stroke: "rgba(225, 225, 225, 1)",
              },
            },
            yAxisIndex: 1,
          },
          {
            name: "完成率",
            data: [0.01,0.2,0.3,0.01,0.4,0.5,0.1],
            type: "line",
            label:{
              show:true,
            },
            gradient: {
              color: ["rgba(225, 225, 225, 1)", "rgba(103, 224, 227, 1)"],
            },
            barStyle: {
              stroke: "rgba(225, 225, 225, 1)",
              color: "#fff",
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang="scss">
.item-container{
    height: 35vh !important;
}
.task-container {
  height: calc(35vh - 40px);
}
</style>