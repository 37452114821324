<template>
    <Panel class="item-container food-container uk-animation-slide-right" style="margin-top: 10px">
            <template slot="outer">
                <div class="item-title">
                    <span>近30天重点抽查食材</span>
                        <input class="input" v-model="text" type="text">
                    <span>
                        <Icon type="ios-resize" size="18" />
                    </span>
                </div>
                <div class="task-container">
                    <dv-loading class="loading" v-if="!isShow">正在加载中...</dv-loading>
                    <div class="table">
                        <div class="table-header">
                            <div class="table-header-item flex-1">序号</div>
                            <div class="table-header-item flex-5">机构名称</div>
                            <div class="table-header-item flex-1">操作</div>
                        </div>
                        <div class="table-body">
                            <div @click="onClick(index)" v-for="(item,index) in tmpData" :class="index%2==0?'active':''" :key="item[0]" class="table-body-row">
                                <div class="table-item flex-1">{{item[0]}}</div>
                                <div class="table-item flex-5">{{item[1]}}</div>
                                <div class="table-item flex-1">播放</div>
                            </div>
                        </div>
                    </div>
                    <!-- <dv-scroll-board  v-if="isShow" :config="configData" style="color:#c1c7c9; font-size:13px;height:340px;" class="task-table" /> -->
                </div>
            </template>
            
        </Panel>
</template>
<script>
import {
    Panel,
} from "../index.js";
export default {
    components:{Panel},
    created(){
        this.$get("/publicity/getUnqualifiedMap?deptId=2&deptType=4%2C7&date=day").then(res=>{
            console.log(res)
            const tmpConfigData = this._.cloneDeep(this.configData)
            tmpConfigData.data = []
            res.result.forEach((item,index)=>{
                tmpConfigData.data.push([item.deptId,item.deptName,item.latitude,item.longitude])
                window.addMarker(item.longitude,item.latitude,item.deptName,item.sampleCount,item.unqualifiedNumber)
            })
            
            this.tmpData = tmpConfigData.data
            this.configData = tmpConfigData
            this.isShow = true
        })
    },
    watch:{
        text(n,o){
            const tmp = this.configData.data.filter((item,index,arr)=>{
                // console.log(item[1],item[1].indexOf(n) >= 0,n)
                return item[1].indexOf(n) >= 0
            })
            this.tmpData = tmp
            console.log(tmp.length)
        }
    },
    data(){
        return{
            tmpData:[],
            text:"",
            isShow:false,
            configData: {
        header: ["序号", "机构名称", "操作"],
        data: [
            ["01","杭州市江干区托尼幼儿园"],
            ["01","杭州市江干区托尼幼儿园"],
            ["01","杭州市江干区托尼幼儿园"],
            ["01","杭州市江干区托尼幼儿园"],
            ["01","杭州市江干区托尼幼儿园"],
            ["01","杭州市江干区托尼幼儿园"]
        ],
      },
        }
    },
    methods:{
        onClick(index){
            console.log(this.tmpData[index])
            window.moveTo(this.tmpData[index][3],this.tmpData[index][2])
            
        }
    }
}
</script>
<style  lang="scss">
    .food-container{
    height: 35vh !important;
    }
 ::-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   }
.flex-1{
    flex:1;
}
.flex-2{
    flex:2;
}
.flex-3{
    flex:3;
}
.flex-4{
    flex:4;
}
.flex-5{
    flex:5;
}
.flex-6{
    flex:6;
}
.flex-7{
    flex:7;
}
.item-title{
    display: flex;
}
.active{
    background-color: rgb(9,18,45) !important;
}
.table{
    // padding:4px;
    height: calc(35vh - 40px);
    overflow: auto;
    text-align: center;
    color: rgb(193, 199, 201);
    &-header{
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        background-color: rgb(14, 30, 78);
        // background-color: rgb(9,18,45);
    }
    &-body{
        
        &-row{
            display: flex;
            justify-content: space-around;
            height: 38.4px;
            align-items: center;
            background-color: rgb(42,48,66);
        }
    }
}
.input{
        width: 150px;
        background-color: rgba(225,225,225,0.1);
        border:none;
        outline: none;
        color: #fff;
    }
  .task-container {
    // padding:4px;
    height: calc(35vh - 40px);
   overflow: auto;
    
    .task-table {
      width: 100%;
    }
  }
</style>