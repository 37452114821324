<template>
    <Panel class="item-container uk-animation-slide-left" style="margin-top: 10px">
            <template slot="outer">
               <dv-loading class="loading" v-if="!isShow">正在加载中...</dv-loading>
                <div class="item-title">
                    <span>本月检测机构排名</span>
                    <span>
                        <Icon type="ios-resize" size="18" />
                    </span>
                </div>
                <div class="task-container">
                    <dv-scroll-board :config="configData" style="color:#c1c7c9; font-size:13px;" class="task-table" />
                </div>
            </template>
        </Panel>
</template>
<script>
import {
    Panel,
} from "../index.js";
export default {
    components:{Panel},
    created(){
      this.$get("/publicity/getMonthDeptRanking?deptId=2&deptType=4%2C7").then(res=>{
        console.log(res)
        let data = []
        if(res.result){
          res.result.forEach((item,index)=>{
            data.push([index,item.deptName,item.passRate,item.sampleCount])
          })
          let tmpConfigData = this._.cloneDeep(this.configData)
          tmpConfigData.data= data 
          this.configData = tmpConfigData
        }
        this.isShow = true
      }).catch(e=>{
        console.log(e)
      })
    },
    data(){
        return{
          isShow:false,
            configData: {
        header: ["名次", "机构名称", "合格率","检测数"],
        headerBGC: "#0e1e4e",
        headerHeight: 34,
        rowNum: 5,
        oddRowBGC: "#2a3042",
        evenRowBGC: "rgb(9, 18, 45)",
        columnWidth:[60,220],
        align:"left",
        data: [
        ],
      },
        }
    }
}
</script>
<style scoped lang="scss">
  .task-container {
      padding:4px;
    height: calc(29vh - 40px);
   

    .task-table {
      width: 100%;
    }
  }
</style>