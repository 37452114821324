<template>
    <Panel class="item-container uk-animation-slide-left">
            <template slot="outer">
                 <dv-loading class="loading" v-if="!isShow">正在加载中...</dv-loading>
                <div class="item-title">
                    <span>今日检测情况</span>
                    <span>
                        <span style="margin-right:10px;">今日检测数:{{result.dayInspectionCount}}</span>
                        <Icon type="ios-resize" size="18" />
                    </span>
                </div>
                <div class="general-container">
                    <div class="general-left">
                        <dv-charts :option="options[0]" />
                        <div>日检测数</div>
                        <div>{{result.dayInspectionCount}}</div>
                    </div>
                    <div class="general-left">
                        <dv-charts :option="options[1]" />
                        <div>检测比例</div>
                        <div>{{result.dayDeptCompletionRate}}</div>
                    </div>
                    <div class="general-left">
                        <dv-charts :option="options[2]" />
                        <div>合格比例</div>
                        <div>{{result.dayPassRate}}</div>
                    </div>
                    <div class="general-left">
                        <dv-charts :option="options[3]" />
                        <div>不合格数量</div>
                        <div>{{result.dayUnqualifiedNumber||0}}</div>
                    </div>
                    
                </div>
            </template>
        </Panel>
</template>
<script>
import {
    Panel,
} from "../index.js";
export default {
    components:{Panel},
    created(){
        this.$get('/publicity/getInspectionAndDept?deptId=2&deptType=4%2C7').then(res=>{
            console.log(res)
            let tmpOptions = this._.cloneDeep(this.options)
            this.result = res.result 
             tmpOptions[0].series[0].data[0].value = res.result.dayInspectionCount
              tmpOptions[1].series[0].data[0].value = parseInt(parseFloat(res.result.dayDeptCompletionRate))
               tmpOptions[2].series[0].data[0].value = parseInt(parseFloat(res.result.dayPassRate))
                tmpOptions[3].series[0].data[0].value = res.result.dayUnqualifiedNumber
            this.options = []
            for(let i=0;i<tmpOptions.length;i++){
                this.options.push(tmpOptions[i])
               
            }    
            this.isShow = true
        })
    },
    data(){
        return{
            isShow:false,
            result:{},
            options: [{
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      radius:"80%",
      data: [
        { name: 'itemA', value: 65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter: '{value}',
        style: {
            fill: '#1ed3e5',
            fontSize: 14
        }
      }
    }
  ]
},
{
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      radius:"80%",
      data: [
        { name: 'itemA', value: 65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter: '{value}%',
        style: {
            fill: '#1ed3e5',
            fontSize: 14
        }
      }
    }
  ]
},
{
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      radius:"80%",
      data: [
        { name: 'itemA', value: 65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter: '{value}%',
        style: {
            fill: '#1ed3e5',
            fontSize: 14
        }
      }
    }
  ]
},
{
  series: [
    {
      type: 'gauge',
      startAngle: -Math.PI / 2,
      endAngle: Math.PI * 1.5,
      arcLineWidth: 5,
      radius:"80%",
      data: [
        { name: 'itemA', value: 65, gradient: ['#03c2fd', '#1ed3e5', '#2fded6'] }
      ],
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      pointer: {
        show: false
      },
      dataItemStyle: {
        lineCap: 'round'
      },
      details: {
        show: true,
        formatter: '{value}',
        style: {
            fill: '#1ed3e5',
            fontSize: 14
        }
      }
    }
  ]
}]
        }
    }
}
</script>
<style scoped lang="scss">
    .item-container{
        height: 22vh !important;
    }
.general-container {
        // border:1px solid red;
        height: auto;
        padding: 15px 15px 5px 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        color:#fff;
        .general-left {
            // border:1px solid red;
            // width: 10;
            // height: 100vh;
            //align-items: center;
            flex:1;
            height: 10vh;

        }

        .general-item {
            // border:1px solid red;
            height: auto;
            border: 1px solid #373d50;
            border-radius: 3px;
            padding: 2px;
            margin-bottom: 10px;
            margin-top: 15px;

            .item-box {
                background: #373d50;
                width: 100%;
                height: 100%;
                padding: 7px 5px;
                color: #c1c7c9;
                font-size: 13px;
                display: flex;
                justify-content: space-between;

            }

            .item-num {
                color: #07b9e6;
                margin-right: 5px;
                font-size: 14px;
                font-weight: 'din';
            }
        }

        .general-center {
            // border:1px solid red;
            width: 40%;
        }
    }
</style>