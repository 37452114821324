<!--
  功能：项目进度
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2021年12月07日 15:57:58
-->
<template>
<div class="plan-card">
    <div ref="barChart" class="plan-box"></div>
</div>
</template>

<script>
//挂载百度echarts
import * as echarts from "echarts";
export default {
    // 组件名称
    name: "demo",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            myChart: null,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        //绘制图表
        drawChart() {
            this.myChart = echarts.init(this.$refs.barChart);

            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    top: 'top',
                    left: 'left',
                    data: ['未开始',  '进行中','已完成',],
                    textStyle: {
                        color: "#c1c7c9"
                    },
                    itemWidth: 20,
                    itemHeight: 12,

                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '2%',
                    containLabel: true
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#253954',
                            type: 'dashed'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D2E6F9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#c1c7c9'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['厕所', '农贸市场', '河道', '屠宰场', '垃圾房', '马拉松', '饮水', '污水'],
                    axisLine: {
                        lineStyle: {
                            color: '#c1c7c9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#c1c7c9'
                    }
                },
                series: [{
                        name: '未开始',
                        type: 'bar',
                        barWidth: 7,
                        barGap: '40%',
                        data: [18, 23, 29, 18, 23, 29, 18, 6],
                        itemStyle: {
                            barBorderRadius: [0, 0, 0, 0],
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 1,
                                x2: 0,
                                y2: 0,
                                colorStops: [{
                                    offset: 0,
                                    color: '#4be0c0' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#2ACFFC' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        name: '进行中',
                        type: 'bar',
                        barWidth: 7,
                        barGap: '40%',
                        data: [23, 28, 6, 15, 28, 16, 13, 10],
                        itemStyle: {
                            barBorderRadius: [0, 0, 0, 0],
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 1,
                                x2: 0,
                                y2: 0,
                                colorStops: [{
                                    offset: 0,
                                    color: '#5e2abe' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#3253FE' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        name: '已完成',
                        type: 'bar',
                        barWidth: 7,
                        barGap: '40%',
                        data: [6, 32, 20, 16, 22, 10, 26, 12],
                        itemStyle: {
                            barBorderRadius: [0, 0, 0, 0],
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 1,
                                x2: 0,
                                y2: 0,
                                colorStops: [{
                                    offset: 0,
                                    color: '#45d158' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#00FF99' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ]
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                // 执行  图表大小随窗口大小改变而改变
                this.myChart.resize();
            });
        },
    },
    //在实例初始化之后，组件属性计算之前，如data属性等
    beforeCreate() {},
    //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    created() {},
    //在挂载开始之前被调用：相关的 render 函数首次被调用。
    beforeMount() {},
    //挂载后
    mounted() {
        this.drawChart();
    },
    //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
    beforeUpdate() {},
    //数据更新时后调用
    updated() {},
    // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
    activated() {},
    // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
    deactivated() {},
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
    beforeDestroy() {},
    //Vue 实例销毁后调用。
    destroyed() {},
};
</script> 

<style lang="scss" scoped>
.plan-card {
    // border: 1px solid blue;
    padding: 0px;
    .plan-box {
        height: 23vh;
        width: 99%;
        margin: auto;  
    }
}
</style>
