<!--
  功能：项目进度
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2021年12月07日 15:57:58
-->
<template>
<div class="plan-card">
    <div ref="instrumentChart" class="plan-box"></div>
</div>
</template>

<script>
//挂载百度echarts
import * as echarts from "echarts";
export default {
    // 组件名称
    name: "demo",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            myChart: null,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        //绘制图表
        drawChart() {
            this.myChart = echarts.init(this.$refs.instrumentChart);

            var demoData = [{
                name: '项目总数',
                unit: '个',
                value: 96,
            }];
            var option = {
                // backgroundColor: rgb(8, 35, 75,0.75),
                series: (function () {
                    var result = [];

                    demoData.forEach(function (item) {
                        result.push({
                            name: item.name,
                            type: 'gauge',
                            radius: '70.10%',
                            startAngle: 225,
                            endAngle: -45,
                            min: 0,
                            max: 100,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    width: 20,
                                    shadowBlur: 0,
                                    color: [
                                        [0, 'transparent'],
                                        [0.1, '#194383'],
                                        [0.108, 'transparent'],
                                        [0.2, '#194383'],
                                        [0.208, 'transparent'],
                                        [0.3, '#194383'],
                                        [0.308, 'transparent'],
                                        [0.4, '#194383'],
                                        [0.408, 'transparent'],
                                        [0.5, '#194383'],
                                        [0.508, 'transparent'],
                                        [0.6, '#194383'],
                                        [0.608, 'transparent'],
                                        [0.7, '#194383'],
                                        [0.708, 'transparent'],
                                        [0.8, '#194383'],
                                        [0.808, 'transparent'],
                                        [0.9, '#194383'],
                                        [0.908, 'transparent'],
                                        [1, '#194383']
                                    ],
                                }
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: 0,
                            },
                            axisLabel: {
                                show: 0
                            },
                            pointer: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [{
                                show: false,
                            }]
                        }, {
                            name: "项目个数",
                            type: 'gauge',
                            radius: '73.10%',
                            startAngle: 225,
                            endAngle: -45,
                            min: 0,
                            max: 100,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    width: 150,
                                    color: [
                                        [
                                            item.value / 100, new echarts.graphic.LinearGradient(
                                                0, 1, 0, 0, [{
                                                        offset: 0,
                                                        color: 'rgba(69, 161, 255,0)',
                                                    }, {
                                                        offset: 0.3,
                                                        color: 'rgba(69, 161, 255,0)',
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: 'rgba(69, 161, 255,0.7)',
                                                    }
                                                ]
                                            )
                                        ],
                                        [
                                            1, 'rgba(28,128,245,.0)'
                                        ]
                                    ],
                                }
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: 0,
                            },
                            axisLabel: {
                                show: 0
                            },
                            pointer: {
                                show: false,
                                length: '100%'
                            },
                            detail: {
                                show: true,
                                offsetCenter: [1, '1%'],
                                textStyle: {
                                    fontSize: 20,
                                    color: '#07b9e6',
                                    fontWeight: 500,
                                },
                                formatter: [
                                    '{value}' + (item.unit || '')
                                ].join('\n'),

                            },
                            itemStyle: {
                                color: 'rgba(28, 128, 245,.3)',
                                borderColor: 'rgba(28, 128, 245,1)',
                            },
                            data: [{
                                value: item.value
                            }]
                        }, {
                            type: 'gauge',
                            radius: '90%',
                            splitNumber: 10,
                            splitLine: {
                                show: false
                            },
                            min: 0,
                            max: 100,
                            startAngle: 225,
                            endAngle: -45,
                            axisLabel: {
                                show: false
                            },
                            axisLine: {
                                show: false,

                            },

                            pointer: {
                                show: 0
                            },
                            axisTick: {
                                show: false
                            },
                            detail: {
                                show: true,
                                offsetCenter: [0, '50%'],
                                formatter: '项目总数',
                                textStyle: {
                                    fontSize: 20,
                                    color: 'red',
                                    lineHeight: 50,
                                    fontWeight: '100',

                                }

                            }
                        }, {
                            type: 'gauge',
                            radius: '73%',
                            splitNumber: 10,
                            splitLine: {
                                show: false
                            },
                            min: 0,
                            max: 100,
                            startAngle: 225,
                            endAngle: -45,
                            axisLabel: {
                                show: false
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    width: 2,
                                    shadowBlur: 0,
                                    color: [
                                        [0.05, '#3F6BFC'],
                                        [0.10, '#4072FB'],
                                        [0.15, '#4077F9'],
                                        [0.20, '#417BF9'],
                                        [0.25, '#4282F8'],
                                        [0.30, '#4385F7'],
                                        [0.35, '#4389F6'],
                                        [0.40, '#448FF5'],
                                        [0.45, '#4594F4'],
                                        [0.50, '#4599F3'],
                                        [0.55, '#469EF2'],
                                        [0.60, '#46A3F1'],
                                        [0.65, '#46A6F0'],
                                        [0.70, '#24befe'],
                                        [0.75, '#12d1ff'],
                                        [0.80, '#06defe'],
                                        [0.85, '#05e0ff'],
                                        [0.90, '#06deff'],
                                        [0.95, '#06deff'],
                                        [1, '#06deff']
                                    ],

                                }

                            },
                            pointer: {
                                show: 0
                            },
                            axisTick: {
                                show: false
                            },
                            detail: {
                                show: false
                            }

                        }, {
                            type: 'gauge',
                            radius: '82%',
                            splitNumber: 10,
                            splitLine: {
                                show: false
                            },
                            min: 0,
                            max: 100,
                            startAngle: 225,
                            endAngle: -45,
                            axisLabel: {
                                show: false
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    width: 2,
                                    shadowBlur: 0,
                                    color: [
                                        [0.05, '#0A498E'],
                                        [0.10, '#0C58A7'],
                                        [0.15, '#254DD3'],
                                        [0.20, '#355FEC'],
                                        [0.25, '#4072FB'],
                                        [0.30, '#355FEC'],
                                        [0.35, '#254DD3'],
                                        [0.40, '#06478B'],
                                        [0.45, '#0A246C'],
                                        [0.50, '#071848'],
                                        [0.55, '#071848'],
                                        [0.60, '#0A246C'],
                                        [0.65, '#06478B'],
                                        [0.70, '#254DD3'],
                                        [0.75, '#355FEC'],
                                        [0.80, '#355FEC'],
                                        [0.85, '#254DD3'],
                                        [0.90, '#0C58A7'],
                                        [0.95, '#0A498E'],
                                        [1, '#0A498E']
                                    ],

                                }

                            },
                            pointer: {
                                show: 0
                            },
                            axisTick: {
                                show: false
                            },
                            detail: {
                                show: false
                            }

                        }, );
                    });

                    return result;
                })()
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                // 执行  图表大小随窗口大小改变而改变
                this.myChart.resize();
            });
        },
    },
    //在实例初始化之后，组件属性计算之前，如data属性等
    beforeCreate() {},
    //组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    created() {},
    //在挂载开始之前被调用：相关的 render 函数首次被调用。
    beforeMount() {},
    //挂载后
    mounted() {
        this.drawChart();
    },
    //数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
    beforeUpdate() {},
    //数据更新时后调用
    updated() {},
    // keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
    activated() {},
    // keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
    deactivated() {},
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
    beforeDestroy() {},
    //Vue 实例销毁后调用。
    destroyed() {},
};
</script> 

<style lang="scss" scoped>
.plan-card {
    // border: 1px solid blue;
    padding: 0px;

    .plan-box {
        height: 200px;
        width: 100%;
        margin: auto;
    }
}
</style>
